@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor;
  line-height: 35px;
  &-borderless {
    background-color: $componentBackground;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor;
  }

  &-content {
    color: $textColor;
    background: $componentBackground;
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0;
}
