@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix},
.#{$antPrefix}-form-item {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;

    .#{$prefix}-drag-icon:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      border-color: $primaryColor;
    }
  }
}