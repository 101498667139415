@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
}

.#{$prefix} .#{$prefix}-sider {
  background: $primaryColor;
}