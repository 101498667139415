@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;

  >.#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }

  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {

    &-active,
    &:hover,
    &-active .#{$prefix}-tab-btn {
      color: $primaryColor;
    }

    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}