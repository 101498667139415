@import 'variables';

$prefix: $antPrefix + -table;

%boredStyles {
  &.#{$prefix}-bordered {
    table thead>tr:not(:last-child)>th {
      border-color: $borderColor;
    }

    thead>tr>th,
    tbody>tr>td,
    tfoot>tr>th,
    tfoot>tr>td {
      border-color: $borderColor;
    }

    .#{$prefix}-container {
      border-color: $borderColor;
    }

    .#{$prefix}-expanded-row-fixed::after {
      border-color: $borderColor;
    }
  }
}

.#{$prefix} {
  background: transparent;

  .row-danger,
  .row-warning,
  .row-success {
    &>td {
      position: relative;

      &:first-of-type::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 3px;
        left: 0;
        top: 0;
      }
    }
  }

  .row-warning {
    &>td {
      background: rgba(239, 133, 0, 0.15) !important;

      /* color: #faad14 !important; */
      &:first-of-type::before {
        background-color: #faad14;
      }
    }
  }

  .row-danger {
    &>td {
      background: $errorA08  !important;

      /* color: #ff4d4f !important; */
      &:first-of-type::before {
        background-color: $errorColor;
      }
    }
  }

  .row-success {
    &>td {
      background: rgba(76, 183, 91, 0.08) !important;

      /* color: #52c41a !important; */
      &:first-of-type::before {
        background-color: #52c41a;
      }
    }
  }

  .title-center {
    span {
      justify-content: center;
    }
  }

  .text,
  .title {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }
  }

  .check-in-field-title {
    text-align: center;

    .#{$prefix}-header-column {
      width: 10vw;
      white-space: normal;
    }
  }

  .tree-expand-placeholder {
    .#{$prefix}-row-expand-icon {
      margin-right: 0px;
      margin-left: 8px;
    }
  }

  .no-padding {
    padding: 0px;
  }

  .title-avatar-indent {
    .#{$prefix}-header-column {
      >div {
        text-indent: 63px;
      }
    }
  }

  .title-large-icon-indent {
    .#{$prefix}-header-column {
      >div {
        text-indent: 12px;
      }
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .small-head-padding & {
    .#{$prefix}-thead>tr>th {
      padding: 6px;
    }
  }

  .#{$prefix}-thead>tr>th,
  .#{$prefix}-tbody>tr>td {
    white-space: nowrap;
  }

  .#{$prefix}-row-level-1 {
    .#{$prefix}-row-expand-icon {
      display: none;
    }

    td {
      border-bottom-color: transparent;
    }
  }

  .row--nested-table {
    padding: 0px 50px 0px 0px;
    margin: -3px -16px -6px;

    table {
      background-color: transparent;

      thead {
        background-color: transparent;

        >tr>th {
          background-color: transparent;
        }
      }

      tbody {
        background-color: transparent;

        >tr>td {
          border: none;
        }

        .#{$prefix}-row:hover>td {
          background: transparent;
        }
      }
    }
  }

  .#{$prefix} {

    &-thead>tr>th,
    &-thead>tr>td {
      position: relative;
      color: $textColor;
      background: $clientColor;
      border-color: $primaryColor;

      .#{$prefix}-filter-column,
      .#{$prefix}-column-sorters {

        .#{$prefix}-filter-trigger-container .#{$antPrefix}-dropdown-trigger,
        .#{$prefix}-column-sorter,
        .#{$prefix}-column-sorter-full {
          color: $textColorSecondary;
          background: $contrastBackground;

          &:hover {
            color: $primaryColor;
            background: $contrastBackground;
          }
        }
      }

      &.#{$prefix}-column-has-sorters:hover {
        background: $contrastBackground;
      }
    }

    &-tbody {
      &>tr:not(.#{$prefix}-expanded-row)>td {
        background: $clientColor;

        >.anticon:not(.anticon-delete),
        >a:not(.#{$antPrefix}-button) .anticon {
          color: $primaryColor;
        }
      }

      &>tr.#{$prefix}-expanded-row .#{$prefix}-tbody tr td {
        background: $contrastBackground;
      }

      &>tr {
        &>td {
          border-color: $contrastBackground;
          color: $textColor;
        }

        &.#{$prefix}-row-hover,
        &:hover {
          &:not(.#{$prefix}-expanded-row):not(.#{$prefix}-row-selected) {
            &>td {
              background: $itemHoverBackground;
            }
          }
        }
      }

      tr.#{$prefix}-placeholder:hover>td.#{$prefix}-cell {
        background: $clientColor;
      }
    }

    &-placeholder {
      background: $clientColor;
      border: 1px solid $contrastBackground;

      .#{$antPrefix}-empty {
        color: $disabledColor;

        &-image {
          svg g g[fill-rule='nonzero'] {
            stroke: $borderColor  !important;

            path {
              &:first-child {
                fill: $clientColor  !important;
              }

              &:last-child {
                fill: $contrastBackground  !important;
              }
            }
          }

          svg ellipse {
            fill: $contrastBackground  !important;
          }
        }
      }
    }

    &-header {
      background: transparent;
    }
  }

  .#{$prefix}-row.hide-expand-icon .#{$prefix}-row-expand-icon {
    cursor: default;
  }

  .#{$prefix}-row:not(.hide-expand-icon) .#{$prefix}-row-expand-icon {
    background-color: $bodyBackground;
    border-color: $bodyBackground;
  }

  tr.#{$prefix}-expanded-row,
  tr.#{$prefix}-expanded-row:hover {
    background: $contrastBackground;

    .row--nested-table table thead>tr>th {
      border-color: $borderColor;
    }
  }

  tr.#{$prefix}-row-selected {
    td {
      background: $contrastBackground;
    }

    &:hover {
      td {
        background: $contrastBackground;
      }
    }
  }

  &-filter-dropdown {
    background: $contrastBackground;

    .#{$antPrefix}-input {
      background: $contrastBackground;
    }

    &-btns {
      border-color: $primaryColor;
    }
  }

  @extend %boredStyles;
}

.#{$prefix}-filter-column {
  .#{$prefix}-filter-column-title {
    padding: 0 !important;
    flex: unset !important;
    //background-color: #5469d4 !important;

    .#{$prefix}-column-sorters-with-tooltip {
      width: unset !important;
    }
  }

  .#{$prefix}-filter-trigger-container {
    position: initial !important;
    left: 0;

    .#{$prefix}-filter-trigger .anticon {
      left: unset !important;
      margin-left: -5px;
      top: calc(50% + 1px) !important;
    }

    //right: unset !important;
  }
}

@media screen and (max-width: 768px) {

  table {
    table-layout: auto !important;
    width: 100% !important;
    max-width: 100% !important;

    tbody,
    tr {
      min-width: 100%;
    }
  }

  .#{$prefix}-thead,
  .#{$prefix}-ping-right .#{$prefix}-cell-fix-right-first::after,
  .#{$prefix}-ping-right .#{$prefix}-cell-fix-right-last::after {
    display: none;
  }

  .#{$prefix}-thead>tr th:first-of-type,
  .#{$prefix}-tbody>tr th:first-of-type,
  .#{$prefix}-thead>tr td:first-of-type,
  .#{$prefix}-tbody>tr td:first-of-type {
    padding-top: 16px;
    min-width: 100% !important;
  }

  .#{$prefix}-thead>tr th:last-of-type,
  .#{$prefix}-tbody>tr th:last-of-type,
  .#{$prefix}-thead>tr td:last-of-type,
  .#{$prefix}-tbody>tr td:last-of-type {
    padding-bottom: 16px;
  }

  .#{$prefix}-thead>tr>th,
  .#{$prefix}-tbody>tr>th,
  .#{$prefix}-thead>tr>td,
  .#{$prefix}-tbody>tr>td {
    display: block;
    min-width: 100% !important;
    border: none;
    padding: 4px 16px;
    font-size: 16px;
    white-space: normal !important;
  }

  .#{$prefix}-thead>tr>th:last-child,
  .#{$prefix}-tbody>tr>th:last-child,
  .#{$prefix}-thead>tr>td:last-child,
  .#{$prefix}-tbody>tr>td:last-child {
    border-bottom: 1px solid $borderColor;
  }
}