@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
    color: $textColor;
}

.#{$prefix}.login-title {
    text-align: center;
    color: $textColorSecondary;
    margin-bottom: 32px;
    // text-shadow: -1px -1px 0 $secondaryColor, 1px -1px 0 $secondaryColor, -1px 1px 0 $secondaryColor, 1px 1px 0 $secondaryColor;
}