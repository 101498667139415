@import 'variables';

.grid-header {
  margin-bottom: 12px;

  .grid-title .#{$antPrefix}-typography, .grid-date .#{$antPrefix}-typography {
    font-size: 16px;
  }

  .grid-title {
    font-weight: 600;
  }
}

.grid-table {
  .#{$antPrefix}-table.#{$antPrefix}-table-small .#{$antPrefix}-table-thead {
    > tr > th {
      padding: 4px 8px;
    }
  }

  .#{$antPrefix}-table-tbody {
    > .row-color-added:not(.#{$antPrefix}-table-expanded-row) > td {
      background-color: $addColorLight;
    }

    > .row-color-added-selected:not(.#{$antPrefix}-table-expanded-row) > td {
      background-color: $addColorLight;
      border: 3px solid rgba(1, 34, 44, 0.877);
    }

    > .row-color-deleted:not(.#{$antPrefix}-table-expanded-row) > td {
      background-color: $errorColorLight;
    }

    > .row-color-deleted-selected:not(.#{$antPrefix}-table-expanded-row) > td {
      background-color: $errorColorLight;
      border: 3px solid rgba(1, 34, 44, 0.877);
    }

    > .row-border-selected:not(.#{$antPrefix}-table-expanded-row) > td {
      border: 3px solid rgba(1, 34, 44, 0.877);
    }

    > tr > td:not(:first-child) {
      padding: 0;

      > div {
        padding: 0 8px;
      }

      .column-color-added, .column-color-deleted {
        width: 100%;
        height: 39px;
        display: flex;
        align-items: center;
      }
  
      .column-color-added {
        background: $addColorLight;
      }
  
      .column-color-deleted {
        background: $errorColorLight;
      }
    }
  }

  .#{$antPrefix}-table-thead th, .#{$antPrefix}-table-tbody td {
    .#{$antPrefix}-btn-link {
      padding: 4px 6px;
    }
  }
}