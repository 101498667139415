@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  border-radius: 2px;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom-color: $borderColor;
  }
  &-bordered {
    border-color: $borderColor;
  }

  .#{$prefix}-body {
    padding: 16px;
  }
}
