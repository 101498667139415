@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
  border-radius: 0%;
  padding: 10px 24px 0;

  &-heading-title,
  &-back-button,
  &>.#{$antPrefix}-breadcrumb a:not(:hover),
  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    color: $textColor;
  }

  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }

  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link,
  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link a {
    &:hover {
      background: transparent;
      color: $primaryColor;
    }
  }

  .#{$prefix}-breadcrumb+.#{$prefix}-heading {
    margin-block-start: 0;
  }

  .#{$prefix}-breadcrumb {
    display: none;
  }

  .#{$prefix}-heading {
    .#{$prefix}-heading-left {
      .#{$prefix}-back .#{$prefix}-back-button {

        &:hover,
        &:focus {
          color: $primaryColor;
        }
      }
    }
  }
}