@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
}

.#{$prefix} .#{$prefix}-sider {
  background: $primaryColor;
}

.login-layout {
  min-height: 100vh;
  display: inherit;
  background: url('../../../images/copropriete-loi-elan.jpg');
  background-size: cover;

  .login-form-col {
    background-color: rgba(34, 34, 34, 0.8);
    backdrop-filter: blur(4px);
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 90%;
    }
  }
}